import React from 'react'
import homeImg from './../styles/images/home.png'
// import logo from './../styles/images/logo.svg'
import logoWidth from './../styles/images/LOGO.png'
import {Button, Link, Stack, Typography, useMediaQuery} from "@mui/material";
import {NavLink, useNavigate, useLocation} from "react-router-dom";
import {makeStyles} from "@mui/styles";

const style = {
    backgroundImage: `url(${homeImg})`,
    minHeight: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
}


const useStyles = makeStyles({
    notActive: {
        opacity: .4,
    }
})


const Home = () => {
    const classes = useStyles()
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname;
    const largeScreen = useMediaQuery((theme => theme.breakpoints.up('md')))

    return <div style={style}>
        <Stack alignItems={'center'} style={{minHeight: '100vh', backgroundColor: 'rgba(255, 255, 255, 0.6)'}}>
            <Stack direction={'row'} p={4} justifyContent={'center'} spacing={4}>
                <NavLink to={'/'}>
                    <Link className={path !== '/' ? classes.notActive : ''} color={'#111'} fontSize={18}
                          fontWeight={600}
                          component={'button'}
                          underline={'none'}>
                        Home
                    </Link>
                </NavLink>
                <NavLink to={'/about'}>
                    <Link className={path !== '/about' ? classes.notActive : ''} color={'#111'} fontSize={18}
                          fontWeight={600}
                          component={'button'} underline={'none'}>
                        About
                    </Link>
                </NavLink>
                <NavLink to={'/services'}>
                    <Link className={path !== '/services' ? classes.notActive : ''} color={'#111'}
                          fontSize={18}
                          fontWeight={600} component={'button'} underline={'none'}>
                        Services
                    </Link>
                </NavLink>
                <NavLink to={'/contact'}>
                    <Link className={path !== '/contact' ? classes.notActive : ''} color={'#111'}
                          fontSize={18}
                          fontWeight={600} component={'button'} underline={'none'}>
                        Contact
                    </Link>
                </NavLink>
            </Stack>
            <Stack alignItems={'center'} my={largeScreen ? 10 : 5} p={3} justifyContent={'center'} height={'100%'}>
                <Stack direction={'row'}>
                    {/*<img src={logo} alt={'Bushra'} height={largeScreen ? '112px' : '94px'}/>*/}
                    {/*<Stack justifyContent={'center'} ml={3}>*/}
                    {/*    <Typography variant={largeScreen ? 'h2' : 'h3'} color={'primary'}*/}
                    {/*                fontWeight={600}>Bushra</Typography>*/}
                    {/*    <Typography variant={'h6'} color={'black'}>Marketing Management</Typography>*/}
                    {/*</Stack>*/}
                    <img src={logoWidth} alt={'Bushra'} height={largeScreen ? '348px' : '320px'}/>
                </Stack>
                <Typography mb={3} mt={5} fontFamily={'Rubik'} fontSize={largeScreen ? '32px' : '28px'}
                            textAlign={'center'}
                            color={'#111'}
                            fontWeight={500}
                            maxWidth={'md'}>
                    DEVELOP YOUR BUSINESSES AND MANAGE YOUR RISK WITH OUR EXPERTISE
                </Typography>
                <Button variant={'contained'} style={{fontWeight: 600}} onClick={() => navigate('/about')}
                        color={'third'}
                        size={'large'}>More About us</Button>
            </Stack>
        </Stack>
    </div>
}

export default Home;
