import React from "react";
import {Container, Grid, Link, Stack, useMediaQuery} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";
// import logo from "./../styles/images/logo.svg"
import logoWidth from "./../styles/images/LOGO.png"
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    notActive: {
        opacity: .4,
    }
})

const Navbar = () => {
    const classes = useStyles()
    const location = useLocation()
    const path = location.pathname;
    const largeScreen = useMediaQuery((theme => theme.breakpoints.up('md')))

    return <Stack direction={'row'} p={2} mt={largeScreen ? 0 : 2} position={'fixed'} zIndex={'appBar'} top={0}
                  right={0} left={0}
                  bgcolor={'primary.contrastText'}
        // borderBottom={'solid 2px #fff'}
                  justifyContent={'space-around'}
                  alignItems={'center'}>
        <Container maxWidth={'xl'}>
            <Stack direction={'row'}
                   justifyContent={'space-around'}
                   alignItems={'center'}>
                {
                    largeScreen ?
                        <Grid xs={3} display={'flex'} direction={'row'} alignItems={'center'} spacing={2}
                              borderRadius={2}
                              maxHeight={50} p={1}>
                            <img height={'60px'} src={logoWidth} alt=""/>
                            {/*<img width={'50px'} src={logo} alt=""/>*/}
                            {/*<Stack>*/}
                            {/*    <Typography fontWeight={800} color={'primary.main'} variant={'h6'}>Bushra</Typography>*/}
                            {/*    <Typography fontWeight={800} color={'#333'} variant={'subtitle2'}>Marketing*/}
                            {/*        Management</Typography>*/}
                            {/*</Stack>*/}
                        </Grid>
                        : ''
                }
                <Grid xs={6}>
                    <Stack direction={'row'} justifyContent={'center'} spacing={4}>
                        <NavLink to={'/'}>
                            <Link className={path !== '/' ? classes.notActive : ''} color={'#4B4B4B'} fontSize={18}
                                  fontWeight={600}
                                  component={'button'}
                                  underline={'none'}>
                                Home
                            </Link>
                        </NavLink>
                        <NavLink to={'/about'}>
                            <Link className={path !== '/about' ? classes.notActive : ''} color={'#4B4B4B'} fontSize={18}
                                  fontWeight={600}
                                  component={'button'} underline={'none'}>
                                About
                            </Link>
                        </NavLink>
                        <NavLink to={'/services'}>
                            <Link className={path !== '/services' ? classes.notActive : ''} color={'#4B4B4B'}
                                  fontSize={18}
                                  fontWeight={600} component={'button'} underline={'none'}>
                                Services
                            </Link>
                        </NavLink>
                        <NavLink to={'/contact'}>
                            <Link className={path !== '/contact' ? classes.notActive : ''} color={'#4B4B4B'}
                                  fontSize={18}
                                  fontWeight={600} component={'button'} underline={'none'}>
                                Contact
                            </Link>
                        </NavLink>
                    </Stack>
                </Grid>
                {
                    largeScreen ?
                        <Grid xs={3}>
                        </Grid>
                        : ''
                }
            </Stack>
        </Container>
    </Stack>
}

export default Navbar
