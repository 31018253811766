import React from 'react'
import Navbar from "../Component/Navbar";
import {Button, Container, Paper, Stack, Typography, useMediaQuery} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles({
    spacingLi: {
        '& li': {
            marginBottom: '10px'
        }
    }
})

const Services = () => {
    const largeScreen = useMediaQuery((theme => theme.breakpoints.up('md')))
    const navigate = useNavigate()
    const classes = useStyles()

    return <Stack minHeight={'calc(100vh)'}
                  bgcolor={'primary.contrastText'}>
        <Navbar/>
        <Container maxWidth={'md'}>
            <Typography variant={'h4'} mb={2} mt={largeScreen ? 'calc(10vh + 68px)' : 'calc(5vh + 68px)'}>Why Choose
                us?</Typography>
            <Typography fontSize={18}>We enable companies to grow sales, profits and share in the global marketplace
                through our end-
                to-end marketing analytics solutions, whether you&#39;re looking to develop more effective ways to
                plan, implement and measure everyday marketing activities or solve more complex business
                challenges.
            </Typography>
            <Typography mt={4} mb={3} fontSize={18}>Bushra Marketing Management provides the following services to help
                clients optimize
                marketing performance:</Typography>
            <Button variant={'contained'} onClick={() => navigate('/contact')} color={'primary'} size={'large'}>Request
                free audit now</Button>
            <Stack spacing={3} my={4}>
                <Paper elevation={0}>
                    <Typography variant={'h6'} m={2}>
                        Professional Marketing Management Service
                    </Typography>
                    <ul className={classes.spacingLi}>
                        <li>Marketing Planning and Strategy</li>
                        <li>Development of a Marketing Mix</li>
                        <li>Analysis marketing opportunities and Identify target markets</li>
                        <li>Managing & Controlling Marketing efforts</li>
                    </ul>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant={'h6'} m={2}>
                        Market Research
                    </Typography>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant={'h6'} m={2}>
                        Diagnosis Analysis and Market Intelligence Consultancy
                    </Typography>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant={'h6'} m={2}>
                        Measure, Optimize & Forecast Market
                    </Typography>
                </Paper>
                <Paper elevation={0}>
                    <Typography variant={'h6'} m={2}>
                        Marketing Analytics Solutions by:
                    </Typography>
                    <ul className={classes.spacingLi}>
                        <li>Demand Signals</li>
                        <li>Marketing Mix Modeling</li>
                        <li>Multi-Touch Attribution</li>
                        <li>Pricing Analytics</li>
                        <li>Media & Market Testing</li>
                    </ul>
                </Paper>
            </Stack>
        </Container>
    </Stack>
}

export default Services;
