import React from 'react'
import {Button, Container, Stack, Typography, useMediaQuery} from "@mui/material";
import Navbar from "../Component/Navbar";
import {useNavigate} from "react-router-dom";

const About = () => {
    const largeScreen = useMediaQuery((theme => theme.breakpoints.up('md')))
    const navigate = useNavigate()

    return <Stack minHeight={'calc(100vh)'}
                  py={largeScreen ? 0 : 5}
                  bgcolor={'primary.contrastText'} color={'#fff'} alignItems={'center'} justifyContent={'center'}>
        <Navbar/>
        <Container maxWidth={'lg'}>
            <Typography maxWidth={largeScreen ? '70%' : '100%'} fontFamily={'Roboto'} fontWeight={700}
                        fontSize={largeScreen ? 38 : 32}
                        color={'primary.dark'}
                        mt={largeScreen ? 'calc(68px)' : 'calc(32px)'}>
                We are Bushra Marketing Management, a newly established company in the UAE, with a team
                of educated market analysts and experience on international trade.
            </Typography>
            <Stack maxWidth={largeScreen ? '60%' : '100%'} mt={8} ml={'auto'}>
                <Typography fontSize={largeScreen ? 24 : 20} color={'primary.dark'}
                >
                    Business strategists and marketing professionals from Bushra Marketing Management consult
                    businesses on marketing products and services to attract and retain customers, including help
                    with planning, pricing, sales, and promotion, as well as help to make higher profits by applying
                    scientific solutions.
                </Typography>
                <Stack maxWidth={'fit-content'} mt={3}>
                    <Button variant={'contained'} onClick={() => navigate('/services')} size={'large'}
                            color={'primary'}>Our
                        Services</Button>
                </Stack>
            </Stack>
        </Container>
    </Stack>
}

export default About;
