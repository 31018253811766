import {createTheme, ThemeProvider} from "@mui/material";
import React from "react";

export const theme = createTheme({
    typography: {
        allVariants: {
            textTransform: "none",
        },
    },
    palette: {
        primary: {
            main: '#40B26D',
            contrastText: '#E7EDED',
            light: '#A5C3C1',
            dark: '#002A27',
        },
        secondary: {
            main: '#A5C3C1',
            contrastText: '#002A27',
        },
        third: {
            main: '#002A27',
            contrastText: '#A5C3C1',
        },
        background: {
            paper: '#FDFDFD',
            default: ''
        }
    }

})

export const Theme = (props) => {
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}
