import React from 'react'
import ContactForm from "../Component/ContactForm";
import {Button, Container, Grid, IconButton, Link, Stack, Typography, useMediaQuery} from "@mui/material";
import {Email, FmdGood, Call, Facebook, Instagram, Twitter,LinkedIn} from "@mui/icons-material";
import styled from "@emotion/styled";
import {theme} from "../styles/theme";
import Navbar from "../Component/Navbar";

const Item = styled((props) => <Button {...props}/>)({
    fontSize: '16px',
    color: theme.palette.primary.dark,
    '& span': {
        color: theme.palette.primary.light
    }
})

const Contact = () => {
    const largeScreen = useMediaQuery((theme => theme.breakpoints.up('md')))
    return <Stack minHeight={'calc(100vh)'}
                  bgcolor={'primary.contrastText'}>
        <Navbar/>
        <Container maxWidth={'lg'}>
            <Typography fontWeight={700} mt={largeScreen ? 'calc(10vh + 68px)' : 'calc(5vh + 68px)'} mb={4}
                        variant={'h3'} color={'primary.dark'}>Get a quote</Typography>
            <Grid justifyContent={'space-between'} container>
                <Grid xs={12} md={5}>
                    <Typography fontWeight={400} pr={2} color={'primary.dark'} variant={'body1'}>If you are interested
                        in finding out how Bushra Marketing Management can assist you with
                        marketing attribution and challenges, please contact us.</Typography>
                    <Stack mt={3} spacing={1} alignItems={'start'}>
                        <Item variant={'text'} up startIcon={<Email/>}>
                            info@bushramarket.com
                        </Item>
                        <Item variant={'text'} up startIcon={<FmdGood/>}>
                            P.O.123128box Dubai, UAE
                        </Item>
                        <Item variant={'text'} up startIcon={<Call/>}>
                            +971525924472
                        </Item>
                    </Stack>
                    <Stack mt={3} direction={'row'}>
                        {/*<Link href={'#'}>*/}
                        {/*    <IconButton color={'secondary'}>*/}
                        {/*        <Facebook/>*/}
                        {/*    </IconButton>*/}
                        {/*</Link>*/}
                        <Link href={'https://www.instagram.com/bushramarket_com'}>
                            <IconButton color={'secondary'}>
                                <Instagram/>
                            </IconButton>
                        </Link>
                        {/*<Link href={'#'}>*/}
                        {/*    <IconButton color={'secondary'}>*/}
                        {/*        <Twitter/>*/}
                        {/*    </IconButton>*/}
                        {/*</Link>*/}
                        <Link href={'https://www.linkedin.com/company/bushra-marketing-management'}>
                            <IconButton color={'secondary'}>
                                <LinkedIn/>
                            </IconButton>
                        </Link>
                    </Stack>
                </Grid>
                <Grid xs={12} md={6} mt={largeScreen ? 0 : 4} mb={4}>
                    <ContactForm/>
                </Grid>
            </Grid>
        </Container>
    </Stack>
}

export default Contact;
