import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import About from "./pages/About";
import Navbar from "./Component/Navbar";
import {Theme} from "./styles/theme";
import {QueryParamProvider} from "use-query-params";

function App() {
    return (
        <Theme>
            <BrowserRouter>
                <QueryParamProvider>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="contact" element={<Contact/>}/>
                        <Route path="services" element={<Services/>}/>
                        <Route path="about" element={<About/>}/>
                    </Routes>
                </QueryParamProvider>
            </BrowserRouter>
        </Theme>);
}

export default App;
