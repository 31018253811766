import React, {useState} from "react";
import {Alert, Button, Checkbox, FormControlLabel, FormGroup, Stack, TextField} from "@mui/material";
import {api} from "../api/axios";
import {useForm} from "react-hook-form";

const ContactForm = () => {
    const {register, handleSubmit, formState: {errors}} = useForm()
    const [message, setMessage] = useState({
        type: '',
        text: ''
    });
    const sendMessage = (data) => {
        api.post('/contact', data).then(response => {
            if (response.status === 200) {
                setMessage({
                    type: 'success',
                    text: response.data.message
                });
                console.log(response.data.message)
                setTimeout(() => {
                    setMessage({
                        type: '',
                        text: ''
                    });
                }, 3000)
            } else {
                setMessage({
                    type: 'error',
                    text: response.data.errors.email[0]
                });
                console.log(response)
                setTimeout(() => {
                    setMessage({
                        type: '',
                        text: ''
                    });
                }, 3000)
            }
        }).catch(err => {
            setMessage({
                type: 'error',
                text: err.response.data.errors.email[0]
            });
            setTimeout(() => {
                setMessage({
                    type: '',
                    text: ''
                });
            }, 3000)
        })
    }

    return <form onSubmit={handleSubmit(sendMessage)}>
        <Stack color={'black'} bgcolor={'white'} p={4} borderRadius={4} direction={'column'} spacing={2}>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                <TextField {...register('first_name')} id="first_name" fullWidth required label="First Name"
                           variant="outlined"/>
                <TextField {...register('last_name')} id="last_name" fullWidth required label="Last Name"
                           variant="outlined"/>
            </Stack>
            <TextField {...register('email')} type={'text'} id="email" required label="Email" variant="outlined"/>
            <TextField error={errors.message}
                       helperText={errors.message && errors.message.message} {...register('message', {
                required: 'this field is required.',
                minLength: {
                    value: 10,
                    message: 'this message have to got 10 character'
                }
            })} multiline rows={5} id="message" required label="Message"
                       variant="outlined"/>
            <FormGroup>
                <FormControlLabel control={<Checkbox  {...register('news')}/>} label="Do you want get news from us ?"/>
            </FormGroup>
            <Button type={'submit'} variant={'contained'} size={'large'}>Send Message</Button>
            {message.text !== '' &&
                <Alert severity={message.type === 'error' ? 'error' : 'success'}>{message.text}</Alert>}
        </Stack>
    </form>
}

export default ContactForm;
